export class DisplayJsErrors {
	private container: HTMLElement;

	constructor() {
		window.addEventListener('error', this.listError);
		window.addEventListener('pushpayUnhandledRejection', (e: any) => {
			this.listUnhandledRejection(e.detail);
		});
	}

	getContainer() {
		if (!this.container) {
			this.container = document.createElement('div');
			this.container.id = 'error-list';
			this.container.innerHTML = '<ul></ul>';
			document.body.appendChild(this.container);
		}

		return this.container;
	}

	listError = (event: ErrorEvent) => {
		this.showError(`<b>Error:</b><span>&nbsp;${event.message}</span>
						<b>File:</b><span>&nbsp;${event.filename}</span>
						<b>Line:</b><span>&nbsp;${event.lineno}:${event.colno}</span>`);
	}

	listUnhandledRejection(errorMessage: string) {
		this.showError(`<b>Error:</b><span>&nbsp;${errorMessage}</span>`);
	}

	showError(innerHtml: string) {
		const container = this.getContainer();

		const ul = container.querySelector('ul');
		const li = document.createElement('li');
		li.innerHTML = innerHtml;
		ul.appendChild(li);
	}

	clearErrors() {
		if (this.container) {
			document.body.removeChild(this.container);
			this.container = null;
		}
	}
}

const displayJsErrorsSingleton = new DisplayJsErrors();
export const displayJsErrors = () => displayJsErrorsSingleton;
